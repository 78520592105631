import React, { useEffect, useState } from "react";
import "./contact.css";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import axios from "axios";


export default function Contact() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  let link = process.env.LINK || 'https://sheet.best/api/sheets/d6a1eddc-3487-4cb5-8071-ed0467020f3b';

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);

  const onHandleSubmit = async () => {
    if (!name || !email || !message) {
      return swal("All field are required !!","Please fill all field", "warning");
    }
    const data = {
      Name: name,
      Email: email,
      Message: message,
      Date: new Date().toLocaleDateString(),
    };
    try {
      setLoading(true);
       await axios.post(link, data).then((res)=>{
        setLoading(false);
        swal("Message sent successfully","Will get back to you soon","success").then((res)=>{
          navigate('/');
        })
       })
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return swal("Oops, Something went wrong", "error");
    }
    setLoading(false);
  };

  return (
    <div className="contact p-4">
      <div className="row justify-content-center mx-0 pb-2">
        {/* Left Div */}
        <div
          className="col-md-4 p-2 bg-light rounded mt-5 shadow"
          data-aos="slide-right"
        >
          <h1 className="fw-light text-center">Get in touch</h1>
          <hr />
          <div className="mb-3">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Your Name"
            />
          </div>
          <div className="mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              placeholder="Enter Your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label>Message</label>
            <textarea
              type="email"
              className="form-control"
              placeholder="Enter Your Message Here"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="text-end mb-3">
            <button
              className="btn bg-light custom-btn rounded-pill shadow"
              disabled={loading}
              onClick={onHandleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        {/* Right Div */}
        <div className="col-md-6 p-4 mt-5" data-aos="slide-left">
          <img
            src="/assets/contact.png"
            className="contact-img"
            alt="contact"
          />
        </div>
      </div>
    </div>
  );
}
