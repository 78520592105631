import './App.css';
import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import {Home, About, Contact} from './pages';
import Navbar from './component/navbar/Navbar';
import Footer from './component/footer/Footer';


export default function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />}/>
        <Route path='/about' element={<About />}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

