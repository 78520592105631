import React from 'react'
import './home.css'
import HomeShowcase from '../../component/home/HomeShowCase.jsx';
import SkillGraph from '../../component/home/SkillGraph';
import Project from '../../component/home/Project';
import ScrollToTop from 'react-scroll-to-top'

export default function Home() {
  return (
    <>
    <HomeShowcase />
    <SkillGraph />
    <Project />
    <ScrollToTop />
    </>
  )
}
