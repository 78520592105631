import React, { useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AOS from "aos";

export default function SkillGraph() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  const progressbarStyle = {
    pathColor: `#6c63ff`,
    textColor: `#a1a1a1`,
    backgroundColor: `#3e98c7`,
    pathTransitionDuration: 0.5,
  };

  const skillData = [
    {
      value: 8.5,
      skill: "JAVASCRIPT",
    },
    {
      value: 8,
      skill: "TYPESCRIPT",
    },
    {
      value: 8,
      skill: "HTML",
    },
    {
      value: 7,
      skill: "CSS",
    },
    
    {
      value: 7.5,
      skill: "REACT",
    },
    {
      value: 8,
      skill: "REACT NATIVE",
    },
    {
      value: 8,
      skill: "REDUX",
    },
    {
      value: 8,
      skill: "NODE JS",
    },
    {
      value: 7.5,
      skill: "EXPRESS JS",
    },
    {
      value: 7.5,
      skill: "MONGODB",
    },
  ];

  const Circular = ({ value, skill }) => {
    return (
      <div className="col-md-1 mb-2 skill-graph" data-aos="zoom-in-up">
        <CircularProgressbar
          strokeWidth={12}
          value={value}
          text={`${value}/10`}
          maxValue={10}
          styles={buildStyles({ ...progressbarStyle })}
        />
        <p className="pw-light text-secondary mb-0 text-center">{skill}</p>
      </div>
    );
  };

  return (
    <div className="container-fluid my-5 ">
      <h1 className="text-center fw-light">Skill Graph</h1>
      <hr />
      <div className="row justify-content-center mx-0 ">
        {skillData.map(({ value, skill }, index) => {
          return <Circular value={value} skill={skill} key={index} />;
        })}
      </div>
    </div>
  );
}
