import React,{useEffect}from "react";
import { projectData } from "../../projectData";
import AOS from 'aos';


export default function Project() {
  useEffect(()=>{
    AOS.init({
      offset: 200,
      duration: 400,
      easing: 'ease-in-sine',
      delay: 100,
    })
  },[])
  return (
    <div className="container-fluid my-5 mx-0">
      <h1 className="text-center fw-light">My Projects</h1>
      <hr />
      <div className="container">
        {projectData.map((item, key) => {
          // console.log(item.image);
          return (
            <div
              className="row justify-content-center mx-0 shadow p-4 mb-5"
              key={key}
              data-aos={key%2=== 0 ? "fade-left" :"fade-right"}
            >
              <div className="col-md-6" data-aos="zoom-in">
                <img src={item.image} alt="" className="project-img" />
              </div>
              <div className="col-md-6 ">
                <h3 style={{color:'#6c63ff', fontWeight:'bold'}}>{item.title}</h3>
                <p className="fw-light">{item.info}</p>
                <br/>
                <div className="d-flex justify-content-between">
                  <a
                    href={item.liveLink}
                    target={"_blank"}
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="btn rounded-pill shadow custom-btn">
                      Demo
                    </button>
                  </a>
                  <a
                    href={item.repoLink}
                    target={"_blank"}
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <button className="btn rounded-pill shadow custom-btn">
                      Repo
                    </button>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
