import React, { useEffect } from "react";
import { CgScrollV } from "react-icons/cg";
import Typewriter from "typewriter-effect";
import AOS from "aos";

export default function HomeShowCase() {
  let words = [
    "Proficient in React Native, React, Redux, Node, Express and MongoDB",
  ];
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 1000,
    });
  }, []);
  return (
    <>
      <div className="row justify-content-center mx-0 showcase-div mt-4">
        <div className="col-md-6 showcase-text mt-5" data-aos="slide-left">
          <h1>Hello,</h1>
          <h2>I am Saurabh, a mobile full Stack developer</h2>
          <h2>I love developing full stack application</h2>
          <Typewriter
            options={{
              strings: words,
              autoStart: true,
              loop: true,
              delay: 100,
              wrapperClassName: "typewriter",
            }}
          />
        </div>
        <div className="col-md-6 showcase-right-div" data-aos="slide-right">
          <img src="/assets/device.png" alt="device img" />
        </div>
      </div>
      <div className="text-center">
        <button
          className="btn btn-outline-dark shadow py-2 rounded-pill px-1"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="Scroll-Down"
        >
          <CgScrollV />
        </button>
      </div>
    </>
  );
}
