import React from "react";
import { NavLink } from "react-router-dom";
import './navbar.css';

export default function Navbar() {
  return (
    <div className="container-fluid mx-0 d-flex justify-content-between py-3 bg-light shadow sticky-top">
      <div>
        <NavLink to="/" className="nav-link links">
          <span className="highlight">S</span><span>aurabh's </span>
          <span className="highlight">P</span><span>ortfolio </span>
        </NavLink>
      </div>
      <div className="d-flex">
        <NavLink to='/about' className="nav-link links mx-3">About</NavLink>
        <NavLink to='/contact' className="nav-link links mx-3">Contact</NavLink>
      </div>
    </div>
  );
}
