import React, {useEffect} from "react";
import "./about.css";
import AOS from 'aos';

export default function About() {
  useEffect(()=>{
    AOS.init({
      delay:200,
      offset:200,
      duration: 200,
      easing: 'ease-in-sine',
    })
  },[])

  return (
    <div className="container-fluid my-5 mx-0">
      <div className="text-center">
        <img alt="profile" src="/assets/device.png" className="profile-img" />
        <h1>About Me</h1>
        <hr className="mx-5" />
        <h3>
          I am full stack developer.
          <br />
          Proficient in React Native, React, Redux, Node.js, Express js and
          MongoDB
        </h3>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-md-6 mt-3">
          <h1 className="text-center mt-2 fw-light">Experience</h1>
          <h4 className="text-center fw-light"> I am having 1+ years of experience.
          <br/>
          Worked on multiple projects
          </h4>
        </div>
        <div className="col-md-6 mt-3" data-aos="slide-left">
          <img src='/assets/exp.png' alt="experience" className="about-img" />
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
      <div className="col-md-6 mt-3" data-aos="slide-right">
          <img src='/assets/edu.png' alt="experience" className="about-img" />
        </div>
        <div className="col-md-6 mt-3">
          <h1 className="text-center mt-2 fw-light">Higher Education</h1>
          <h4 className="text-center fw-light">Completed B.E. in Computer Science</h4>
        </div>
      </div>
      <div className="text-center" data-aos="fade-up">
        <a download href="/assets/TestPDFfile.pdf" style={{textDecoration:'none'}}>
          <button className="btn custom-btn rounded-pill px-5 mt-5 shadow">Get My Resume</button>
        </a>
      </div>
    </div>
  );
}
