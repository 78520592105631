import { AiFillGithub, AiFillLinkedin, AiFillMail } from "react-icons/ai";

export const projectData = [
  {
    image: "/assets/admin.png",
    title: "MERN Admin-Dashboard",
    info: "Application build using MERN Stack, admin can view and do analysis of daily, monthly and yearly sales by categorizing products. Charts are used to make admin UI intractive ",
    liveLink: "https://admin-dashboard-n59y.onrender.com/",
    repoLink: "https://github.com/saurabhkara/MERN-Admin-Dashboard",
  },
  {
    image: "/assets/login.png",
    title: "MERN Login App",
    info: "Application developed using MERN stack to understand login auth flow using jwt token, basically to learn and unserstand backend. Only authorize user will be able to view main page",
    liveLink:
      "https://github.com/saurabhkara/MERN-LOGIN-APP",
    repoLink:
      "https://github.com/saurabhkara/MERN-LOGIN-APP",
  },
  {
    image: "/assets/travel.png",
    title: "MERN Travel Diaries",
    info: "Application developed using MERN stack which to store travel memories and photos. User can create their account to share, edit, delete and to view posts on application  ",
    liveLink:
      "https://github.com/saurabhkara/Travel-Diaries-Blog-MERN-frontend",
    repoLink:
      "https://github.com/saurabhkara/Travel-Diaries-Blog-MERN-frontend",
  },
  {
    image: "/assets/gt.png",
    title: "Google Translator Clone",
    info: "Clone build using React Native and used rapid translator api to translate languages to different laguages",
    liveLink: "https://github.com/saurabhkara/Google-Translate-Clone",
    repoLink: "https://github.com/saurabhkara/Google-Translate-Clone",
  },
  {
    image: "/assets/nextBlog.png",
    title: "Next Blog",
    info: "Appication build using Next.js, Typescript and headless CMS for backend. User can create, update and delete their posts  in application ",
    liveLink: "https://github.com/saurabhkara/Nextjs-Blog",
    repoLink: "https://github.com/saurabhkara/Nextjs-Blog",
  },
];

export const socialLinks = [
  {
    icon: <AiFillGithub />,
    link: "https://github.com/saurabhkara",
  },
  {
    icon: <AiFillLinkedin />,
    link: "https://www.linkedin.com/in/saurabhkumar802/",
  },
  {
    icon: <AiFillMail />,
    link: "mailto: saurabhkumar679@gmail.com",
  },
];
