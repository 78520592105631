import React from 'react';
import { socialLinks } from '../../projectData';

export default function Footer() {

  return (
    <div className='container-fluid footer text-light shadow text-center mx-0 py-4'>
      <p className='fw-light'>&copy; Saurabh {new Date().getFullYear()}</p>
      <p className='fw-light'>Thanks for visiting my Portfolio website</p>
      <div className='d-flex justify-content-center'> 
          {
            socialLinks.map((item, index)=>{
              return(
                <a href={item.link} target='_blank' rel="noreferrer" style={{textDecoration:'none',margin:'15px'}} key={index}>
                  <button className="btn bg-light shadow rounded-pill">{item.icon}</button>
                </a>
              )
            })
          }
      </div>
    </div>
  )
}
